<template>
  <div id="holidaysNotTakenView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>claim holidays not taken</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal v-if="selectedVacationRequest" :show="showHolidaysNotTakenModal" title="claim holidays not taken" size="large">
          <form id="holidaysNotTakenForm">
            <fieldset v-bind:disabled="isSavingRequest">
              <div class="form-group row mt-4">
                <span class="col-md-3">start date</span>
                <span>{{ selectedVacationRequest.startDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">end date</span>
                <span>{{ selectedVacationRequest.endDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">resumption date</span>
                <span>{{ selectedVacationRequest.resumptionDate | toLongDateString }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">vacation days</span>
                <span>{{ selectedVacationRequest.duration }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">holidays</span>
                <span>{{ selectedVacationRequest.holidays }}</span>
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">number of days</span>
                <input type="number" id="holidaysNotTakenDays" class="form-control col-md-1" v-model="request.numberOfDays" required min="1" :max="selectedVacationRequest.holidays" />
              </div>
              <div class="form-group row mt-4">
                <span class="col-md-3">reason</span>
                <textarea id="reasonForChange" class="form-control col-md-8" v-model="request.reasonForChange" required rows="3" />
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-3"></label>
                <div class="col-md-8">
                  <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="cancel" v-bind:disabled="isSavingRequest">Cancel</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click.prevent="saveChangeRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="isSavingRequest">
          <employee-autocomplete v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="disableFields" />
        </fieldset>
        <data-table v-if="vacationRequests.length" id='vacationRequests-table' :headers='tableHeaders' :items='vacationRequests'>
          <template v-slot:item.employeeName="{ item }">
            <a href="#" @click="selectRequest(item)">
              {{ item.employeeInfo.fullName }}
            </a>
          </template>
          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | toShortDateString }}
          </template>
          <template v-slot:item.endDate="{ item }">
            {{ item.endDate | toShortDateString }}
          </template>
          <template v-slot:item.duration="{ item }">
            {{ item.duration }}
          </template>
          <template v-slot:item.holidays="{ item }">
            {{ item.holidays }}
          </template>
          <template v-slot:item.resumptionDate="{ item }">
            {{ item.resumptionDate | toShortDateString }}
          </template>
        </data-table>
      </div>
    </div>
    <!--<div class="row">
      <div class="col">

      </div>
    </div>-->
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  input:invalid {
    border: 1px solid red !important;
  }
</style>

<script>
  import store from '@/store'

  import { employeeService, vacationRequestService, leaveRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import Enums from '@/utils/enums'
  import { toShortDateString, toLongDateString } from '@/filters'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import DataTable from '@/components/ui/DataTable'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'

  export default {
    name: 'holidays-not-taken-view',
    components: {
      AppAlert,
      AppModal,
      EmployeeAutocomplete,
      DataTable
    },
    mixins: [GeneralMixin, AlertMixin, ErrorMixin, AccessControlMixin, LeaveRequestMixin],
    data: () => ({
      request: {
        vacationRequestId: null,
        numberOfDays: null,
        intendedApproverId: '',
        intendedApproverName: '',
        requestType: Enums.RequestType.HolidayNotTaken.value,
        requestActions: []
      },
      vacationRequests: [],
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
      },
      showHolidaysNotTakenModal: false,
      selectedVacationRequest: null,
      isSavingRequest: false,
      isChangingUserStatus: false,
      searchedEmployees: [],
      tableHeaders: [
        {
          name: 'employeeName',
          title: 'employee name'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'endDate',
          title: 'end date'
        },
        {
          name: 'duration',
          title: 'duration'
        },
        {
          name: 'holidays',
          title: 'holidays'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        }
      ],

    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee || employee.isExpatriateEmployee)
        }
        return []
      },
      leaveRequestCanBeRaisedForEmployee() {
        return true
      }
    },
    watch: {
      showHolidaysNotTakenModal(value) {
        if (value) {
          this.$nextTick(() => {
            document.getElementById('holidaysNotTakenDays').focus()
          })
        }
      }
    },
    methods: {
      resetRequestData() {
        this.request.employeeId = null
        this.request.vacationRequestId = null
        this.request.numberOfDays = null
        this.request.intendedApproverId = ''
        this.request.intendedApproverName = ''
        this.request.reasonForChange = ''
      },
      resetData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.employeeInfo.supervisorId = ''
        this.employeeInfo.supervisorName = ''
        this.resetRequestData()
        this.vacationRequests.splice(0, this.vacationRequests.length)
        this.showHolidaysNotTakenModal = false
        this.selectedVacationRequest = null
        this.isSavingRequest = false
        this.isChangingUserStatus = false
      },
      fetchEmployeeData(employee) {
        eventBus.$emit(Events.LongOperationStarted, '')
        const self = this
        let tasks = []
        const year = new Date().getFullYear()
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(vacationRequestService.getVacationRequestsForHolidaysNotTaken(employee.employeeId, year))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          const vacationRequests = results[1]
          self.employeeInfo.employeeId = employee.employeeId
          self.employeeInfo.employeeNo = employee.employeeNo
          self.employeeInfo.fullName = employee.fullName
          self.employeeInfo.supervisorId = employee.supervisorId
          self.employeeInfo.supervisorName = employee.supervisorName
          if (vacationRequests && vacationRequests.length) {
            for (const vacationRequest of vacationRequests) {
              self.vacationRequests.push(vacationRequest)
            }
          }
          else {
            self.showErrorMessage('no vacation schedule found to claim holidays not taken')
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      //fetchEmployeeData(employee) {
      //  eventBus.$emit(Events.LongOperationStarted, '')
      //  const self = this
      //  this.employeeInfo.employeeId = employee.employeeId
      //  this.employeeInfo.employeeNo = employee.employeeNo
      //  this.employeeInfo.fullName = employee.fullName
      //  this.employeeInfo.supervisorId = employee.supervisorId
      //  this.employeeInfo.supervisorName = employee.supervisorName
      //  const year = new Date().getFullYear()
      //  vacationRequestService.getVacationRequestsForHolidaysNotTaken(employee.employeeId, year).then((vacationRequests) => {
      //    if (vacationRequests && vacationRequests.length) {
      //      for (const vacationRequest of vacationRequests) {
      //        self.vacationRequests.push(vacationRequest)
      //      }
      //    }
      //    else {
      //      self.showErrorMessage('no vacation schedule found to claim holidays not taken')
      //    }
      //  }).catch((error) => {
      //    self.showErrorMessage(error)
      //  }).finally(() => {
      //    eventBus.$emit(Events.LongOperationCompleted)
      //  })
      //},
      selectRequest(request) {
        this.resetRequestData()
        this.selectedVacationRequest = { ...request }
        this.request.employeeId = this.employeeInfo.employeeId
        this.request.vacationRequestId = request.id
        this.request.intendedApproverId = this.employeeInfo.supervisorId
        this.request.intendedApproverName = this.employeeInfo.supervisorName
        this.showHolidaysNotTakenModal = true
      },
      saveChangeRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            let data = { ...self.request }
            if (!self.request.id) {
              data.employeeId = self.employeeInfo.employeeId
            }
            else {
              data.comment = self.comment
            }
            leaveRequestService.schedule(data, self.employeeInfo.employeeId)
              .then(() => {
                self.requestState = Enums.RequestState.Submitted
                self.showSuccessMessage('request submitted successfully')
                self.isCompleted = true
                self.resetData()
              })
              .catch((error) => {
                console.log(error.appResultCode)
                if (error instanceof ELeaveError) {
                  console.log(error.appResultCode)
                  self.showErrorMessage(ErrorMessages.getErrorMessageByErrorCode(error.appResultCode))
                }
                else {
                  self.showErrorMessage(error)
                }
              }).finally(() => {
                self.isSavingRequest = false
              })
          }

        })
      },
      cancel() {
        this.showHolidaysNotTakenModal = false
      },
      async validateRequest() {
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const requestForm = document.getElementById('holidaysNotTakenForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        else {
          const approverDirectReports = await employeeService.getEmployeeDirectReports(this.request.intendedApproverId)
          if (approverDirectReports && approverDirectReports.length) {
            return true
          }
          else {
            this.showErrorMessage('invalid approver. approver is not a supervisor')
            return false
          }
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchEmployeeData(store.state.userModule.currentUser)
      })
    },
    filters: {
      toShortDateString,
      toLongDateString
    }
  }
</script>
